<template>
  <div class="container">
    <h1>Lekebergs Kommun Fakturor - AI Tolkning</h1>
    <p>
      Inspirerad av
      <a href="https://kommun.jensnylander.com/">Jens Nylanders granskning</a>,
      denna sida fokuserar specifikt på Lekebergs kommun, och erbjuder en
      detaljerad insikt i dess faktureringsmönster. Här finns data gällande de
      leverantörer som debiterat Lekebergs kommun 2022 och 2023.
    </p>
    <p>
      Genom att använda generativ AI, förenklar vi inte bara inhämtning av data,
      men också dess bearbetning och presentation, vilket gör det enklare att
      förstå och analysera faktureringsinformationen.
    </p>
    <p>
      Tjänsten existerar för att lättare kunna ställa frågor gällande den data
      som finns på kommun.jensnylander.com samt som ett exempel på hur
      generativt AI kan användas för som ett annat gränssnitt till stora
      datamängder jämfört exempelvis mer klassiska tabeller.
    </p>
  </div>
</template>

<script>
export default {
  name: "WhyDescription",
};
</script>

<style scoped>
.container {
  max-width: 100%;
  margin: 0 auto;
  background-color: #44475a;
  padding: 10px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  .container {
    max-width: 80vw;
    margin: 0 auto;
    padding: 20px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
}

h1 {
  color: #ff79c6;
  font-family: "newakeFont";
  font-size: 3rem;
}
p {
  color: #f8f8f2;
}
a {
  color: #ff79c6;
}
</style>
