<template>
  <WhyDescription />
  <ChatInterface />
  <MyFooter />
</template>

<script>
import ChatInterface from "./components/ChatInterface.vue";
import WhyDescription from "./components/WhyDescription.vue";
import MyFooter from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    ChatInterface,
    WhyDescription,
    MyFooter,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
body {
  background-color: #282a36;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
footer {
  position: relative;
  bottom: 0;
  width: 100%;
}
@media (min-width: 600px) {
  #app {
    margin-top: 60px;
  }
  footer {
    position: fixed;
  }
}

@font-face {
  font-family: "newakeFont";
  src: url("~@/assets/fonts/Newake-Font-Demo.otf") format("opentype");
}
</style>
