<template>
  <div class="chat-container">
    <div class="messages-container">
      <div v-for="(message, index) in messages" :key="index" class="message">
        {{ message }}
      </div>
    </div>
    <div v-if="loading" class="loader-holder"><div class="loader"></div></div>
    <div class="question-buttons" v-if="showQuestions">
      <button
        v-for="(question, index) in exampleQuestions"
        :key="index"
        class="question-button"
        :hidden="question.hidden"
        @click="selectQuestion(question)"
      >
        <p>{{ question.description }}</p>
      </button>
    </div>
    <div style="position: relative">
      <input
        v-model="newMessage"
        @keyup.enter="sendMessage"
        type="text"
        class="message-input"
        placeholder="Skriv din fråga här... eller välj en av frågorna nedan"
      />
      <button v-if="newMessage.length" @click="sendMessage" class="icon-button">
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>
  </div>
</template>
/* HTML: */
<script>
export default {
  name: "ChatInterface",
  data() {
    return {
      newMessage: "",
      messages: [],
      eventSource: null,
      showQuestions: true,
      exampleQuestions: [
        {
          description:
            "Vilka företag har fått mest pengar från Lekebergs kommun under 2022?",
          query:
            "Vilka fem företag har fått mest pengar från Lekebergs kommun, och hur mycket har de fått?",
          hidden: false,
        },
        {
          description: "Vilka företag i sjukvårdssektorn har debiterat mest?",
          query:
            "Vilka fem företag i sjukvårdssektorn har debiterat mest, hur mycket har de debiterat per företag?",
          hidden: false,
        },
        {
          description: "Vilka företag har debiterat mest per anställd?",
          query:
            "Vilka fem företag har debiterat mest per anställd under 2022, exkludera alla företag med 0 anställda? Presentera hur mycket de debiterat per anställd.",
          hidden: false,
        },
        {
          description: "Hur många företag har debiterat mer än 1 miljon?",
          query: "Hur många företag har debiterat mer än 1 miljon under 2022?",
          hidden: false,
        },
      ],
      loading: false,
    };
  },
  beforeUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  },
  methods: {
    selectQuestion(question) {
      this.newMessage = question.query;
      const currentQuestion = this.exampleQuestions.find((q) => q === question);
      currentQuestion.hidden = true;
      this.sendMessage();
      // this.showQuestions = false;
    },
    sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.connectToStream();
        this.newMessage = "";
      }
    },
    connectToStream() {
      this.loading = true;
      // const url = new URL("http://127.0.0.1:8000/query");
      const url = new URL("https://kommundata-rag.fly.dev/query");

      url.searchParams.append("query", this.newMessage);

      this.eventSource = new EventSource(url);

      this.eventSource.onopen = () => {
        // this.messages.push("Stream Opened.");
        this.messages.push("");
      };

      this.eventSource.onmessage = (e) => {
        if (e.data === "END") {
          // this.messages.push("Stream Ended.");
          this.eventSource.close();
          this.eventSource = null;
          this.loading = false;
        } else {
          this.messages[this.messages.length - 1] += e.data;
        }
      };
      this.eventSource.onerror = (e) => {
        console.error("SSE error:", e);
        this.eventSource.close();
        this.eventSource = null;
      };
    },
  },
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";

.chat-container {
  background-color: #282a36;
  color: #f8f8f2;
  max-height: 9000px;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
}
@media (min-width: 600px) {
  .chat-container {
    max-width: 80vw;
    overflow: hidden;
    max-height: 900px;
  }
}
@media (min-width: 1400px) {
  .chat-container {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.messages-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10px;
  margin-top: 10px;
}

.message {
  margin: 5px 0;
}

.message-input {
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #44475a;
  color: #f8f8f2;
  border: none;
  border-radius: 10px;
}

.message-input::placeholder {
  color: #f8f8f2;
}

.message-input:focus {
  outline: none;
  border: 1px solid #6272a4;
}
.icon-button {
  position: absolute;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  top: 52%;
  right: 15px;
  margin-left: 10px;
}

.icon-button i {
  color: #f8f8f2;
  font-size: 16px;
}
.question-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-top: 20px;
}
@media (min-width: 600px) {
  .question-buttons {
    flex-direction: row;
  }
}

.question-button {
  background-color: #6272a4;
  color: #f8f8f2;
  padding: 15px;
  /* margin-bottom: 10px; */
  border: none;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.question-button:hover {
  background-color: #6e7b91;
}

.question-button p {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1em;
}
.loader-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.loader {
  font-weight: bold;
  width: 70%;
  font-family: sans-serif;
  font-size: 16px;
  padding-bottom: 8px;
  background: linear-gradient(currentColor 0 0) 0 100%/0% 3px no-repeat;
  animation: l2 2s linear infinite;
}
.loader:before {
  content: "Det händer saker...";
}
@keyframes l2 {
  to {
    background-size: 100% 3px;
  }
}
</style>
