<template>
  <footer class="site-footer">
    <div class="footer-content">
      <a href="https://github.com/perstarkse" target="_blank">
        <i class="fab fa-github"></i> GitHub
      </a>
      <span class="separator"></span>
      <a href="mailto:perstark.se@gmail.com">
        <i class="fas fa-envelope"></i> Email
      </a>
      <span class="separator"></span>
      <a href="https://www.linkedin.com/in/per-stark/" target="_blank">
        <i class="fab fa-linkedin-in"></i> LinkedIn
      </a>
    </div>
    <div class="legal">
      <p>
        CC BY-NC-SA 4.0 | Ingen data sparas | Av Per Stark | Nyttja ansvarsfullt
        snälla ❤️
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MyFooter",
};
</script>

<style scoped>
.site-footer {
  background-color: #282a36;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content a {
  color: #ff79c6;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-content a i {
  margin-right: 5px;
}

.separator {
  display: inline-block;
  margin: 0 10px;
}

.separator::after {
  content: "|";
  color: #fff;
}

.footer-content a:hover {
  text-decoration: underline;
}

.legal p {
  margin-top: 10px;
  font-size: 0.8em;
}
</style>
